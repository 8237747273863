import request from '@/utils/request'

export function listApi(data) {
    return request({
        url: '/advert/roleMenu/menulist',
        method: 'post',
        data
    })
}

export function saveApi(data) {
    return request({
        url: '/advert/roleMenu/save',
        method: 'post',
        data
    })
}

export function listmenuMyApi(data) {
    return request({
        url: '/advert/roleMenu/mymenulist',
        method: 'post',
        data
    })
}

