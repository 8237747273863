<template>
  <div>

    <el-row>
      <el-button type="primary" @click="saveRoleMenu">保存</el-button>
    </el-row>

    <br />
    <el-tree
        :data="ddate"
        show-checkbox
        node-key="id"
        ref="tree"
        @check-change="handleCheckChange"
        :default-expanded-keys="['100', '200','300']"
        :default-checked-keys="selectMenu"
        :props="{label:'name'}">
    </el-tree>

  </div>


</template>


<script>
import { listApi,saveApi } from '@/api/sysmenu'

export default {
  name: "sysmenu",
  props: ['row'],
  data(){
    return {
      ddate:[],
      selectMenu:[],

    }
  },
  created() {
    this.getList();


  },
  watch:{
    row: function (n ,o) {
      console.info(n,o)
      this.getList();
    }
  },
  methods:{
    getList(){
      let c = {};
      if(this.row && this.row.id){
        c = {roleId: this.row.id}
      }
      //
      listApi(c).then(response => {
        console.info(response)
        this.ddate = response.body.data
        this.selectMenu = response.body.selectData
      })
    },

    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
      let res = this.$refs.tree.getCheckedNodes()
      console.info(res)
    },
    saveRoleMenu(){
      let res = this.$refs.tree.getCheckedNodes()
      console.info(res)
      let ddte ={roleId:this.row.id,list:res}
      saveApi(ddte).then(response => {
        console.log(response)
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
      })

    }

  }

}

</script>

<style scoped>

</style>
